document.querySelectorAll('a[data-smooth-scroll="true"][href^="#"]').forEach((el) => {
  const href = el.getAttribute('href');
  const anchorName = href === '#' ? 'body' : `.anchor[name="${href.slice(1)}"]`;
  const target = document.querySelector(anchorName);

  if (target) {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      target.scrollIntoView({ behavior: 'smooth' });
    });
  }
});
